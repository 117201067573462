@import "../sprite";

.nav-block {
  background-color: #1e5284;
  padding: 11px 0;
}

.nav-block__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-block__button {
  font-size: 0;
  background: none;
  border: none;
  outline: none;

  &::before {
    content: '';
    display: inline-block;
    @include sprite($icon-menu);
  }
}

.nav__phone {
  font-weight: 700;
  color: #fff;

  &::before {
    content: '';
    display: inline-block;
    @include sprite($icon-phone);
    margin-right: 11px;
    margin-bottom: -1px;
  }
}