.article {
  font-size: 13px;
  color: #1e5284;
  line-height: 1.5;

  .container {
    padding-top: 25px;
  }
}

.article__list {
  padding-left: 30px;
  margin: 0 0 20px;

  &--numeric {
    margin: 20px 0;
  }
}

.article__list > .article__item > .article__list {
  margin: 0;
}

.article__text {
  margin: 20px 0;
}

.article__list-title {
  font-weight: 700;
  margin: 20px 0 0;
}

.article__span {
  font-weight: 700;
  text-transform: uppercase;
}

.article__table-wrapper {
  margin: 20px 0;
  overflow-x: scroll;
}

.article__table {
  border-collapse: collapse;

  th {
    border: 1px solid #1e5284;
  }

  td {
    box-sizing: border-box;
    height: 50px;
    padding: 20px;
    border: 1px solid #1e5284;

    &:first-child {
      width: 60px;
    }

    &:nth-child(2) {
      width: 157px;
    }

    &:nth-child(3) {
      width: 155px;
    }

    &:last-child {
      width: 230px;
    }
  }
}
