@import "../sprite";

.nav {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #1e5284;
  z-index: 999;
  padding-top: 70px;
  padding-left: 50px;
  display: none;
  box-sizing: border-box;

  &--open {
    display: block;
  }
}

.nav__close {
  font-size: 0;
  position: absolute;
  top: 20px;
  right: 20px;

  &::before {
    content: '';
    display: inline-block;
    @include sprite($icon-close);
  }
}

.nav__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.nav__link {
  font-size: 14px;
  line-height: 36px;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
}