.counter {

}

.counter__wrapper {
  background-color: #f5f4f2;
  border-radius: 3px;
  padding: 30px;
  text-align: center;
}

.counter__title {
  font-size: 28px;
  line-height: 36px;
  text-transform: uppercase;
}

.counter__content {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.counter__item {
  width: 52px;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('../img/counter-bg.png');
  color: #f5f5f5;
  font-size: 46px;

  &:not(:first-child) {
    margin-left: 1px;
  }
}

.counter__text {
  font-size: 13px;
  line-height: 18px;
  margin-top: 20px;
  letter-spacing: -.3px;
}

.counter__button {
  margin-top: 20px;

  .btn {
    width: 100%;
  }
}