@import "../sprite";

.reviews {
  .swiper-container {
    background-color: #f5f4f2;
  }

  .swiper-slide {
    text-align: center;
    font-size: 15px;
    line-height: 1.3;
  }

  .swiper-button-next {
    background-size: auto;
    @include sprite($icon-review-right);
    top: 100px;
    right: 30px;
  }

  .swiper-button-prev {
    background-size: auto;
    @include sprite($icon-review-left);
    top: 100px;
    left: 30px;
  }
}

.reviews__img {
  margin: 40px auto;
}

.reviews__text {
  text-align: center;
  padding: 0 30px;
}

.reviews__separator {
  width: 284px;
  height: 1px;
  background-color: #dfdedb;
  margin: 28px auto;
}

.reviews__author {
  font-weight: 700;
  text-transform: uppercase;
}

.reviews__city {
  font-size: 13px;
  margin-bottom: 40px;
}