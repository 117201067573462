// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$icon-arrow-down-name: 'icon-arrow-down';
$icon-arrow-down-x: 0px;
$icon-arrow-down-y: 7px;
$icon-arrow-down-offset-x: 0px;
$icon-arrow-down-offset-y: -7px;
$icon-arrow-down-width: 12px;
$icon-arrow-down-height: 7px;
$icon-arrow-down-total-width: 38px;
$icon-arrow-down-total-height: 342px;
$icon-arrow-down-image: '../img/sprite.png';
$icon-arrow-down: (0px, 7px, 0px, -7px, 12px, 7px, 38px, 342px, '../img/sprite.png', 'icon-arrow-down', );
$icon-arrow-top-name: 'icon-arrow-top';
$icon-arrow-top-x: 0px;
$icon-arrow-top-y: 0px;
$icon-arrow-top-offset-x: 0px;
$icon-arrow-top-offset-y: 0px;
$icon-arrow-top-width: 12px;
$icon-arrow-top-height: 7px;
$icon-arrow-top-total-width: 38px;
$icon-arrow-top-total-height: 342px;
$icon-arrow-top-image: '../img/sprite.png';
$icon-arrow-top: (0px, 0px, 0px, 0px, 12px, 7px, 38px, 342px, '../img/sprite.png', 'icon-arrow-top', );
$icon-bonuses-name: 'icon-bonuses';
$icon-bonuses-x: 0px;
$icon-bonuses-y: 122px;
$icon-bonuses-offset-x: 0px;
$icon-bonuses-offset-y: -122px;
$icon-bonuses-width: 28px;
$icon-bonuses-height: 24px;
$icon-bonuses-total-width: 38px;
$icon-bonuses-total-height: 342px;
$icon-bonuses-image: '../img/sprite.png';
$icon-bonuses: (0px, 122px, 0px, -122px, 28px, 24px, 38px, 342px, '../img/sprite.png', 'icon-bonuses', );
$icon-close-name: 'icon-close';
$icon-close-x: 0px;
$icon-close-y: 14px;
$icon-close-offset-x: 0px;
$icon-close-offset-y: -14px;
$icon-close-width: 11px;
$icon-close-height: 11px;
$icon-close-total-width: 38px;
$icon-close-total-height: 342px;
$icon-close-image: '../img/sprite.png';
$icon-close: (0px, 14px, 0px, -14px, 11px, 11px, 38px, 342px, '../img/sprite.png', 'icon-close', );
$icon-conditions-name: 'icon-conditions';
$icon-conditions-x: 0px;
$icon-conditions-y: 146px;
$icon-conditions-offset-x: 0px;
$icon-conditions-offset-y: -146px;
$icon-conditions-width: 28px;
$icon-conditions-height: 24px;
$icon-conditions-total-width: 38px;
$icon-conditions-total-height: 342px;
$icon-conditions-image: '../img/sprite.png';
$icon-conditions: (0px, 146px, 0px, -146px, 28px, 24px, 38px, 342px, '../img/sprite.png', 'icon-conditions', );
$icon-experience-name: 'icon-experience';
$icon-experience-x: 0px;
$icon-experience-y: 98px;
$icon-experience-offset-x: 0px;
$icon-experience-offset-y: -98px;
$icon-experience-width: 28px;
$icon-experience-height: 24px;
$icon-experience-total-width: 38px;
$icon-experience-total-height: 342px;
$icon-experience-image: '../img/sprite.png';
$icon-experience: (0px, 98px, 0px, -98px, 28px, 24px, 38px, 342px, '../img/sprite.png', 'icon-experience', );
$icon-inst-name: 'icon-inst';
$icon-inst-x: 0px;
$icon-inst-y: 274px;
$icon-inst-offset-x: 0px;
$icon-inst-offset-y: -274px;
$icon-inst-width: 30px;
$icon-inst-height: 30px;
$icon-inst-total-width: 38px;
$icon-inst-total-height: 342px;
$icon-inst-image: '../img/sprite.png';
$icon-inst: (0px, 274px, 0px, -274px, 30px, 30px, 38px, 342px, '../img/sprite.png', 'icon-inst', );
$icon-menu-name: 'icon-menu';
$icon-menu-x: 0px;
$icon-menu-y: 218px;
$icon-menu-offset-x: 0px;
$icon-menu-offset-y: -218px;
$icon-menu-width: 32px;
$icon-menu-height: 26px;
$icon-menu-total-width: 38px;
$icon-menu-total-height: 342px;
$icon-menu-image: '../img/sprite.png';
$icon-menu: (0px, 218px, 0px, -218px, 32px, 26px, 38px, 342px, '../img/sprite.png', 'icon-menu', );
$icon-phone-footer-name: 'icon-phone-footer';
$icon-phone-footer-x: 0px;
$icon-phone-footer-y: 304px;
$icon-phone-footer-offset-x: 0px;
$icon-phone-footer-offset-y: -304px;
$icon-phone-footer-width: 38px;
$icon-phone-footer-height: 38px;
$icon-phone-footer-total-width: 38px;
$icon-phone-footer-total-height: 342px;
$icon-phone-footer-image: '../img/sprite.png';
$icon-phone-footer: (0px, 304px, 0px, -304px, 38px, 38px, 38px, 342px, '../img/sprite.png', 'icon-phone-footer', );
$icon-phone-name: 'icon-phone';
$icon-phone-x: 0px;
$icon-phone-y: 38px;
$icon-phone-offset-x: 0px;
$icon-phone-offset-y: -38px;
$icon-phone-width: 14px;
$icon-phone-height: 14px;
$icon-phone-total-width: 38px;
$icon-phone-total-height: 342px;
$icon-phone-image: '../img/sprite.png';
$icon-phone: (0px, 38px, 0px, -38px, 14px, 14px, 38px, 342px, '../img/sprite.png', 'icon-phone', );
$icon-reputation-name: 'icon-reputation';
$icon-reputation-x: 0px;
$icon-reputation-y: 170px;
$icon-reputation-offset-x: 0px;
$icon-reputation-offset-y: -170px;
$icon-reputation-width: 28px;
$icon-reputation-height: 24px;
$icon-reputation-total-width: 38px;
$icon-reputation-total-height: 342px;
$icon-reputation-image: '../img/sprite.png';
$icon-reputation: (0px, 170px, 0px, -170px, 28px, 24px, 38px, 342px, '../img/sprite.png', 'icon-reputation', );
$icon-review-left-name: 'icon-review-left';
$icon-review-left-x: 0px;
$icon-review-left-y: 52px;
$icon-review-left-offset-x: 0px;
$icon-review-left-offset-y: -52px;
$icon-review-left-width: 12px;
$icon-review-left-height: 23px;
$icon-review-left-total-width: 38px;
$icon-review-left-total-height: 342px;
$icon-review-left-image: '../img/sprite.png';
$icon-review-left: (0px, 52px, 0px, -52px, 12px, 23px, 38px, 342px, '../img/sprite.png', 'icon-review-left', );
$icon-review-right-name: 'icon-review-right';
$icon-review-right-x: 0px;
$icon-review-right-y: 75px;
$icon-review-right-offset-x: 0px;
$icon-review-right-offset-y: -75px;
$icon-review-right-width: 12px;
$icon-review-right-height: 23px;
$icon-review-right-total-width: 38px;
$icon-review-right-total-height: 342px;
$icon-review-right-image: '../img/sprite.png';
$icon-review-right: (0px, 75px, 0px, -75px, 12px, 23px, 38px, 342px, '../img/sprite.png', 'icon-review-right', );
$icon-simplicity-name: 'icon-simplicity';
$icon-simplicity-x: 0px;
$icon-simplicity-y: 194px;
$icon-simplicity-offset-x: 0px;
$icon-simplicity-offset-y: -194px;
$icon-simplicity-width: 28px;
$icon-simplicity-height: 24px;
$icon-simplicity-total-width: 38px;
$icon-simplicity-total-height: 342px;
$icon-simplicity-image: '../img/sprite.png';
$icon-simplicity: (0px, 194px, 0px, -194px, 28px, 24px, 38px, 342px, '../img/sprite.png', 'icon-simplicity', );
$icon-view-name: 'icon-view';
$icon-view-x: 0px;
$icon-view-y: 25px;
$icon-view-offset-x: 0px;
$icon-view-offset-y: -25px;
$icon-view-width: 18px;
$icon-view-height: 13px;
$icon-view-total-width: 38px;
$icon-view-total-height: 342px;
$icon-view-image: '../img/sprite.png';
$icon-view: (0px, 25px, 0px, -25px, 18px, 13px, 38px, 342px, '../img/sprite.png', 'icon-view', );
$icon-vk-name: 'icon-vk';
$icon-vk-x: 0px;
$icon-vk-y: 244px;
$icon-vk-offset-x: 0px;
$icon-vk-offset-y: -244px;
$icon-vk-width: 30px;
$icon-vk-height: 30px;
$icon-vk-total-width: 38px;
$icon-vk-total-height: 342px;
$icon-vk-image: '../img/sprite.png';
$icon-vk: (0px, 244px, 0px, -244px, 30px, 30px, 38px, 342px, '../img/sprite.png', 'icon-vk', );
$spritesheet-width: 38px;
$spritesheet-height: 342px;
$spritesheet-image: '../img/sprite.png';
$spritesheet-sprites: ($icon-arrow-down, $icon-arrow-top, $icon-bonuses, $icon-close, $icon-conditions, $icon-experience, $icon-inst, $icon-menu, $icon-phone-footer, $icon-phone, $icon-reputation, $icon-review-left, $icon-review-right, $icon-simplicity, $icon-view, $icon-vk, );
$spritesheet: (38px, 342px, '../img/sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
