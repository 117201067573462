.footer {
  background: #015289 url("../img/footer-bg.jpg") 50% 0 repeat-x;
  color: #fff;
  font-size: 14px;
  text-align: center;

  .container {
    padding: 50px 0;
  }
}

.footer__logo {
  margin-bottom: 47px;
}

.footer__phone {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 25px;

  &::before {
    content: "";
    display: block;
    width: 38px;
    height: 38px;
    background: url("../img//icons/icon-phone-footer.png");
    margin-right: 20px;
  }
}

.footer__address {
  line-height: 1.5;
  margin-bottom: 25px;
}

.footer__social {
  width: 70px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto 45px;
}

.footer__copy {
  color: #f5f5f5;
  opacity: .7;
  margin-bottom: 25px;
}

.footer__developed {
  color: #f5f5f5;
  opacity: .7;

  a {
    color: #f5f5f5;
    text-decoration: none;
  }
}