.index-help {
  padding-bottom: 30px;
}

.index-help__img {
  display: block;
  margin: 30px auto 0;
}

.index-help__text {
  margin-top: 25px;
  font-size: 13px;
  line-height: 19px;

  &--margin {
    margin-top: 0;
  }
}

.index-help__list {
  margin: 25px 0 0;
  padding: 0;
  list-style-type: none;
  font-size: 13px;
  line-height: 19px;
}

.index-help__slogan {
  font-size: 13px;
  font-weight: 700;
  margin-top: 25px;
}