@import "../sprite";

.drop-menu {
  margin: 40px 0;
}

.drop-menu__wrapper {
  position: relative;
}

.drop-menu__active {
  display: flex;
  height: 53px;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: #f5f4f2;
  border-radius: 8px;
  color: #1e5284;
  text-decoration: none;

  &::after {
    content: '';
    display: inline-block;
    @include sprite($icon-arrow-down);
  }

  &--open {
    border-radius: 8px 8px 0 0;

    &::after {
      @include sprite($icon-arrow-top);
    }

    & + .drop-menu__list {
      display: block;
    }
  }
}

.drop-menu__list {
  margin: 0;
  padding: 0 20px;
  list-style-type: none;
  background-color: #f5f4f2;
  border-radius: 0 0 8px 8px;
  display: none;
}

.drop-menu__item {
  border-top: 1px solid #dfdedb;
}

.drop-menu__link {
  color: #1e5284;
  text-decoration: none;
  display: inline-block;
  width: 100%;
  padding: 14px 0;
}