.feedback {
  .container {
    padding-top: 25px;
  }
}

.feedback__list {
  margin: 30px auto;
}

.feedback__item {
  margin-bottom: 10px;
  background-color: #f5f4f2;
  border-radius: 4px;
  padding: 30px 20px;
  font-size: 15px;
  color: #1e5284;
}

.feedback__title {
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 10px;
}

.feedback__date {
  opacity: .5;
  margin-bottom: 20px;
  font-size: 14px;
}

.feedback__text {
  line-height: 1.4;
  font-size: 13px;

  &--hidden {
    height: 126px;
    overflow-y: hidden;
    margin-bottom: 20px;
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      box-shadow: inset 0 -16px 10px -10px #f5f4f2;
    }
  }
}

.feedback__link {
  color: #dc3b00;
  text-decoration: none;
  font-size: 13px;
}

.feedback__pagination-list {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  font-size: 22px;
}

.feedback__pagination-item {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;

  &--active {
    border: 2px solid #dc3b00;
    border-radius: 50%;
  }

  a {
    text-decoration: none;
    color: #1e5284;
  }
}