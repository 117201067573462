.btn {
  margin: 0;
  padding: 0;
  text-decoration: none;
  display: inline-block;

  &--default {
    border-radius: 8px;
    font-size: 20px;
    color: #fff;
    background-color: #b92c12;
    padding: 13px 0 14px;
  }
}

.title {
  font-weight: 400;
  color: #1e5284;
  position: relative;

  &--default {
    font-size: 22px;
    line-height: 30px;
    padding-bottom: 15px;
  }

  &::before {
    content: '';
    width: 170px;
    height: 2px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #bb3118;
  }

  &--center {
    text-align: center;

    &::before {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &--upper {
    text-transform: uppercase;
  }
}