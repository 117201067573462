.about-index {
  padding-top: 18px;
  padding-bottom: 30px;
}

.about-index__text {
  font-size: 13px;
  line-height: 19px;
  margin-top: 20px;

  span {
    color: #9c0032;
  }
}