@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800&subset=cyrillic');

body {
  background-color: #fff;
  font-family: 'Open Sans', 'Arial', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #1e5284;
}

.container {
  width: 320px;
  min-width: 320px;
  margin: 0 auto;
  position: relative;
  padding: 0 20px;
  box-sizing: border-box;
}

