.breadcrumbs__list {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 13px;
}

.breadcrumbs__item {
  display: inline;
  color: #757575;

  &:not(:last-child)::after {
    content: ' / ';
    display: inline;
    color: #de3000;
  }
}

.breadcrumbs__link {
  color: #de3000;
}