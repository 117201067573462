@import "../sprite";

.benefits {
  background-color: #1e5284;
  padding: 35px 0;
  color: #fff;
}

.benefits__title {
  text-align: center;
  font-size: 22px;
  line-height: 30px;
  text-transform: uppercase;
}

.benefits__list {
  margin: 30px 0 0;
  padding: 0;
  list-style-type: none;
}

.benefits__item {
  height: 50px;
  padding: 0 25px;
  display: flex;
  align-items: center;

  &:nth-child(odd) {
    background-color: #1d5890;
  }

  &::before {
    content: '';
    display: inline-block;
    margin-right: 30px;
  }

  &--conditions::before {
    @include sprite($icon-conditions);
  }

  &--experience::before {
    @include sprite($icon-experience);
  }

  &--reputation::before {
    @include sprite($icon-reputation);
  }

  &--simplicity::before {
    @include sprite($icon-simplicity);
  }

  &--bonuses::before {
    @include sprite($icon-bonuses);
  }
}