.header {
  padding: 19px 0 21px;
  text-align: center;
}

.header__logo {
  display: inline-block;

  img {
    display: block;
  }
}