.request__text {
  margin: 30px auto 25px;
  text-align: center;
  font-size: 13px;
}

.request__item {
  .btn {
    width: 100%;
    border: none;
    outline: none;
  }
}

.request__form {
  margin-bottom: 62px;
}

.input {
  &--default {
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    padding: 0 20px;
    background-color: #f5f5f5;
    border-radius: 4px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    font-size: 13px;

    &:focus {
      outline: none;
      border-color: #ccd2da;
    }

    &::placeholder {
      font-size: 13px;
    }
  }
}

