@import "../sprite";

.news {
  font-size: 14px;
  color: #1e5284;

  .container {
    padding-top: 25px;
  }
}

.news__list {
  margin: 30px auto 40px;
}

.news__item {
  border-bottom: 2px solid #f5f4f2;
}

.news__title {
  margin: 18px 0 13px;
  text-transform: uppercase;
  color: #dc3b00;
}

.news__link {
  font-size: 13px;

  a {
    text-decoration: none;
    color: #dc3b00;
  }
}

.news__subtitle {
  line-height: 1.5;
  margin-bottom: 15px;
}

.news__counter {
  font-size: 12px;
  color: #555;
  display: inline-block;

  &::before {
    content: '';
    width: 18px;
    height: 13px;
    display: inline-block;
    background-size: auto;
    @include sprite($icon-view);
    opacity: .6;
    margin-right: 5px;
    vertical-align: top;
  }
}

.news__info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}