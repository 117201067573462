.contacts {
  font-size: 15px;
  color: #1e5284;

  .container {
    padding-top: 25px;
  }
}

.contacts__list {
  list-style: none;
  margin: 25px 0 40px;
  padding: 0;
}

.contacts__item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 25px;
}

.contacts__img {
  margin-right: 30px;
}

.contacts__title {
  margin-bottom: 15px;
  font-weight: 700;
}

.contacts__subtitle {
  line-height: 1.4;
}

.contacts__requisites {
  width: 100%;
  box-sizing: border-box;
  padding: 30px 18px;
  border-radius: 4px;
  background-color: #f5f4f2;
  margin-bottom: 40px;
}

