.promo {
  padding-top: 180px;
  background: #fff url('../img/promo-bg.jpg') 50% 0 no-repeat;
  padding-bottom: 40px;
  border-bottom: 2px solid #b92c12;
}

.promo__title {
  text-align: center;
  font-weight: 800;
  color: #1e5284;
  font-size: 34px;
  line-height: 41px;
  text-transform: uppercase;
}

.promo__help {
  font-weight: 700;
  text-align: center;
  line-height: 24px;
}

.promo__text {
  line-height: 24px;
  text-align: center;
  margin-top: 20px;
}

.promo__button {
  margin-top: 20px;
  text-align: center;

  .btn {
    width: 100%;
  }
}